import React, { FC } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { EmailIcon } from "@chakra-ui/icons";

export type AlertSupportProps = {
  cancelRef: any;
  onClose: () => void;
  isOpen: boolean;
  defaultSupportMessage?: string;
};

export const AlertSupport: FC<
  AlertSupportProps
> = ({
  cancelRef,
  onClose,
  isOpen,
  defaultSupportMessage,
}: AlertSupportProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay
        opacity={0.5}
        style={{
          backdropFilter: "blur(10px)",
          backgroundColor: "transparent!important",
        }}
      />
      <AlertDialogContent
        borderRadius={"20px"}
        boxShadow={"0px 4pt 12pt #30435829"}
        width={"95%"}
      >
        <AlertDialogHeader textAlign="center" mr="10px">{t("supportDialog.tooltipText")}</AlertDialogHeader>
        <AlertDialogCloseButton/>
        <AlertDialogFooter p={0}>
          <Button
            borderBottomRadius={"20px"}
            borderTopRadius={0}
            color={"#304358"}
            fontFamily={"Mulish"}
            fontSize={{base: "12px", md: "14px"}}
            height="55px"
            ref={cancelRef}
            width="100%"
            onClick={() => {
              onClose();
              window.open(
                `mailto:${t("invalidRegisterTokenSupportDialog.helpContact")}`,
                "_blank"
              );
            }}
            leftIcon={<EmailIcon />}
          >
            <a
              href={`mailto:${t(
                "invalidRegisterTokenSupportDialog.helpContact"
              )}`}
            >
              {t("invalidRegisterTokenSupportDialog.helpContact")}
            </a>
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
