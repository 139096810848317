const fr = {
    translation: {
        lang: 'fr',
        defaultMessages: {
            //
            //nPGreet: 'Bonjour {{first_name}}, je suis votre assistant virtuel. Je suis là pour vous aider lorsque vous avez une question d\'ordre médical ou si vous avez besoin de soins.',
            nPGreet: 'Bienvenue sur Accès Santé Plus! Veuillez noter qu’en cas d’urgence médicale, vous devez composer le 911 ou vous rendre au service des urgences. Nous tenons à vous informer que toute consultation virtuelle est limitée et de ce fait, le professionnel de santé pourrait vous recommander de vous rendre en personne à une consultation médicale. Acceptez-vous de poursuivre? Veuillez inscrire « oui » et notre coordonnatrice de soins sera avec vous dans un instant.',
            nPGreet2: 'Comment puis-je vous aider aujourd\'hui?',
            nPGreetCorona: 'Bienvenue sur Accès Santé Plus! Veuillez noter qu’en cas d’urgence médicale, vous devez composer le 911 ou vous rendre au service des urgences. Nous tenons à vous informer que toute consultation virtuelle est limitée et de ce fait, le professionnel de santé pourrait vous recommander de vous rendre en personne à une consultation médicale. Acceptez-vous de poursuivre? Veuillez inscrire « oui » et notre coordonnatrice de soins sera avec vous dans un instant.',
            nPGreetCorona2: 'Veuillez décrire les symptômes que vous ressentez actuellement.',
            nPThanking: 'Merci de m\'avoir fourni ces informations. Veuillez patienter quelques instants, j\'achemine ces informations au professionnel de la santé qui vous répondra sous peu.'
        },
        loginScreen: {
            linkEnglish: 'English',
            linkFrench: 'Français',
            greetingTitle: 'Bienvenue',
            pageTitle: 'S\'IDENTIFIER',
            labelEmailInputText: 'Adresse courriel',
            labelPasswordInputText: 'Mot de passe',
            loginBtnText: 'S\'identifier',
            helpLinkText: "j'ai oublié mon mot de passe?",
            question: 'Vous n\'avez pas de compte?',
            link_register: 'Cliquez ici pour créer un compte',
        },
        chatScreen: {
            headerTitle: 'Discutez avec un Navigateur des soins',
            connectedToNP: 'Vous êtes connecté à {{doctor}} {{role}}',
            chatHistoryLabel: 'Ouvrir l\'historique des discussions',
            downloadPDFLabel: 'Télécharger le fichier PDF',
            files_limit: "*Visualiser les documents téléchargés (maximum de 5 fichiers)",
            back_btn: "Retour", 
        },
        forgotPasswordScreen: {
            pageTitle: 'Mot de passe oublié?',
            subTitle: 'Entrez votre adresse courriel ci-dessous et nous vous remettrons sur la bonne voie.',
            labelEmailInputText: 'Adresse courriel',
            labelCaptchaInputText: 'Veuillez saisir le texte ci-dessous',
            captchaInputTextPlaceholder: 'Veuillez saisir le texte ici',
            btnResetPasswordText: 'Réinitialiser le mot de passe',
            linkForgotCredsText: 'paramètres de connexion oubliés?'
        },
        resetPwdLinkSent: {
            pageTitle: 'Vérifiez votre email',
            text1: 'Si votre adresse e-mail est enregistrée dans Accès Santé Plus records, vous recevrez un e-mail.',
            text2: 'Veuillez cliquer sur le lien pour réinitialiser votre mot de passe.',
            login: 'Connexion'
        },
        createNewPassword: {
            pageTitle: 'Créez votre mot de passe',
            newPwdInpLabel: 'Choisissez un mot de passe (min 8 caractères)',
            cnfNewPwdInpLabel: 'Confirmer le mot de passe',
            resetPwdBtn: 'Réinitialiser le mot de passe',
            setPwdBtn: 'Créer un mot de passe',
            yourPasswordHasBeenReset: 'Votre mot de passe a été réinitialisé',
            resetYourPassword: 'Réinitialiser votre mot de passe',
            iAcceptThe: 'J\'accepte les',
            termsAndConditions: 'termes et conditions'
        },
        chatTypeScreen: {
            pageTitle: 'Bonjour {{name}}',
            subTitle: 'Comment pouvons-nous vous aider?',
            labelNewVisit: 'Débuter / Poursuivre la consultation',
            labelCovidVisit: 'J\'aimerai procéder à une évaluation pour le Coronavirus',
            loadingTitle: 'S\'il vous plaît, attendez...',
            loadingSubTitle: 'Nous mettons à jour votre type de visite dans notre base de données. Cela peut prendre un peu de temps.',
            appointmentBtnTitle: 'Prendre un rendez-vous',
            buySingleBtnTitle: 'Acheter une visite à  la carte - ${{price}}',
            buyAnnualBtnTitle: 'Acheter un abonnement annuel - ${{price}}', 
            billingBtnTitle: 'Gestion de la facturation',
            logout: 'Se déconnecter',  
        },
        createPwdAgreement: {
            pageTitle: 'Créez votre mot de passe',
            subTitle: 'Afin de créer votre compte, veuillez accepter les ',
            agreementLinkTitle: 'termes et conditions',
            agreementLinkTitleNew: 'Termes et conditions',
            agreementLink: '#',
            pp: '/pp_fr.html',
            declineBtnTxt: 'Refuser',
            acceptBtnTxt: 'Accepter'
        },
        supportDialog: {
            title: 'Récupérer vos paramètres de connexion',
            helpLabel: 'Veuillez contacter le département de soutien pour obtenir vos paramètres de connexion',
            helpTitle: 'Département de soutien Tap medical',
            helpContact: 'support.accessanteplus@tapmedical.ca',
            callBtnText: 'Contacter l\'équipe de soutien',
            tooltipText: 'Besoin d\'aide? Envoyez-nous un courriel à:', 
        },
        invalidTokenSupportDialog: {
            title: 'Oups! Ce lien est expiré. ',
            helpLabel: 'Ce lien de création de mot de passe n\'est plus valide. Veuillez réinitialiser votre mot de passe à nouveau.',
            helpLabelCreatePwd: 'Veuillez contacter notre équipe de soutien afin de recevoir un nouveau lien.',
            helpTitle: 'Tap Medical Support',
            helpText: 'Si vous avez déjà créer votre mot de passe, veuillez cliquer sur le lien ci-dessous.',
            textLink: 'https://allhealth.tapmedical.ca/',
            helpContact: 'support.accessanteplus@tapmedical.ca',
            callBtnText: 'Contacter l\'équipe de soutien'
        },
        invalidRegisterTokenSupportDialog: {
            title: 'Oups! Ce lien est expiré.',
            helpLabel: 'Veuillez contacter notre équipe de soutien afin de recevoir un nouveau lien.',
            helpTitle: 'Tap Medical Support',
            helpContact: 'support.accessanteplus@tapmedical.ca',
            callBtnText: 'Contacter l\'équipe de soutien'
        },
        vCardDialog: {
            title: 'Télécharger la fiche de contact',
            subInfo1: 'Accès Santé Plus',
            subInfo2: 'T: 555 0123 456',
            subInfo3: 'support.accessanteplus@tapmedical.ca'
        },
        errors: {
            misc: 'Une erreur inattendue s\'est produite!',
            auth: 'Ces informations ne correspondent pas à nos enregistrements.',
            captcha: 'La valeur captch n\'est pas valide',
            token: 'Ce lien de réinitialisation du mot de passe n\'est plus valide. Veuillez réinitialiser votre mot de passe.',
            authToken: 'Pour des raisons de sécurité, la durée de validité de ce lien est limité. Veuillez vous reconnecter pour voir votre nouveau message du professionnel de la santé d\'Accès Santé Plus.',
            title_files: "Trop grand nombre de fichiers.",
            text_files: "Choisir un maximun de 5 fichiers.",
            file_validation_title: "Le fichier {{file_name}} est trop volumineux.",
            file_validation_text: "La taille du fichier doit être inférieure à 10 000 ko."
        },
        success: {
            pwdReset: 'Votre mot de passe a été réinitialisé avec succès. Veuillez vous connecter.',
            pwdSet: 'Votre mot de passe a été crée avec succès. Veuillez s\'il vous plait vous connecter'
        },
        registerForm: {
            title: 'Bienvenue à Accès Santé Plus!',
            subTitle1: 'Afin de créer votre compte et de vérifier votre identité, les informations dans le formulaire ci-dessous sont requises. Veuillez compléter le formulaire et nous le renvoyer.',
            subTitle2: 'Dès que vos informations auront été vérifiées, vous recevrez un courriel dans lequel vous pourrez créer votre mot de passe.',
            subTitle3: 'Vous êtes à la veille de recevoir des soins de santé centrés sur vos besoins!',
            lastName: 'Nom de Famille',
            firstName: 'Prénom',
            phoneNumber: 'Numéro de cellulaire',
            faxNumber: 'Numéro de fax',
            address: 'Adresse',
            email2: 'Adresse courriel',
            name: 'Nom',
            province: 'Province',
            childFmd: 'Médecin de famille de l\'enfant (si applicable)',
            spouseTypes: 'Époux(se)',
            spouseFmd: 'Médecin de famille du conjoint (si applicable)',
            spousePharma: 'Pharmacie préférée du conjoint (si applicable)',
            medicareNumber: 'No. de carte assurance maladie',
            medicareExpiration: 'Date d’expiration',
            dob: 'Date de naissance (mm/jj/aaaa)',
            sex: 'Sexe à la naissance (M ou F)',
            mobileNumber: 'Numéro de cellulaire',
            email14AndOver: 'Adresse courriel (si 14 ans ou plus)',
            submit: 'Soumettre',
            removeBtn: 'Supprimer l\'enfant',
            childPharma: 'Pharmacie préférée de l\'enfant (si applicable)',
            ptRecomendation: 'Veuillez les énumérer',
            allergiesQuestion: 'Avez-vous des allergies?',
            medicationsQuestion: 'Prenez-vous des médicaments?',
            healthIssuesQuestion: 'Avez-vous des antécédents médicaux?',
            proceduresQuestion: 'Avez-vous subi des interventions chirurgicales?',
            textSpousePharmacy: 'Avez-vous une pharmacie préférée?',
            textSpouseFamilyDoctor : 'Avez-vous un médecin de famille du conjoint?',
            textSpouse: 'Avez-vous un conjoint?',
            provinces: {
                quebec: 'Québec',
                ontario: 'Ontario',
                nunavut: 'Nunavut',
                alberta: 'Alberta',
                saskatchewan: 'Saskatchewan',
                manitoba: 'Manitoba',
                new_brunswick: 'Nouveau-Brunswick',
                british_columbia: 'Colombie-Britannique',
                prince_edward_island: 'Île du Prince-Édouard',
                nova_scotia: 'Nouvelle-Écosse',
                newfoundland_labrador: 'Terre-Neuve-et-Labrador',
                northwest_territories: 'Territoires du Nord-Ouest ',
                yukon: 'Yukon',
            },
            gender: {
                male: 'Mâle',
                female: 'Femelle'
            },
            ptSection: {
                title: 'Informations Personnelles',
                companyName: 'Nom de l’entreprise',
                mobileNumber: 'Numéro de cellulaire',
                address: 'Adresse',
                city: 'Ville',
                postalCode: 'Code postal',
                email: 'Adresse courriel (autre que votre adresse courriel corporative)',
            },
            fdSection: {
                title: 'Médecin de famille (si applicable)',
                selectText: 'Avez-vous un médecin de famille?',
                subTitle: 'Veuillez nous fournir les informations suivantes',
                yes: "Oui",
                no: "Non",
            },
            pharmacy: {
                title: 'PHARMACIE DE PRÉFÉRENCE (si applicable)',
                selectText: 'Avez-vous une pharmacie de quartier?',
            },
            family: {
                title: 'Informations Familiales',
                child: 'Enfant',
                addChild: 'Ajouter un enfant',
                childInfo: 'Informations sur l\'enfant'
            }
        },
        registerComplete: {
            title: 'Merci d\'avoir terminé le processus d\'inscription.'
        },
        validation: {
            invalid: '{{field}} invalide',
            required: '{{field}} est requis',
            mismatch: '{{field}} ne correspond pas au champ de confirmation {{field}}.',
            pwdInvalid: '{{field}} doit comporter au moins 6 caractères et contenir 1 majuscule, 1 minuscule et 1 caractère numérique.',
            cnfPwdInvalid: 'Le mot de passe ne correspond pas',
            length: '{{field}} doit contenir {{characters}} caractères.',
            oneCapCharacter: '{{field}} doit contenir au moins 1 caractère majuscule.',
            oneLowCharacter: '{{field}} doit contenir au moins 1 caractère minuscule.',
            oneNumericCharacter: '{{field}} doit contenir au moins 1 caractère numérique.',
            oneSpecialCharacter: '{{field}} doit contenir au moins 1 caractère spécial.',
            length_2: '8 caractères et contenir',
            oneCapCharacter_2: '1 caractère majuscule',
            oneLowCharacter_2: '1 caractère minuscule',
            oneNumericCharacter_2: '1 caractère numérique',
            oneSpecialCharacter_2: '1 caractère spécial',
        },
        validation_code: {
            required: "Vous devez entrer votre code de l'employeur", 
            alphaNumeric: "Votre code de l'employeur ne doit contenir que 6 caractères alphabétiques (A-Z)",
            invalid: "Ce code de l'employeur n'est pas valide", 
            email_error: "Cette adresse courriel est déjà associé à un compte",
        },
        new_register: {
            step: 'Étape',
            next_step: 'Suivant',
            previous_step: 'Précédent',
            next_question: 'Question suivante',
            previous_question: 'Question précédente',
            current_step: '{{step1}} de {{step2}} - ',
            step_title_1: 'Informations Personnelles',
            step_title_2: 'Lieu et langue',
            step_title_3: 'Mon médecin de famille?',
            step_title_4: 'Mot de passe',
            step_title_5: 'Médecin de famille de votre conjoint(e)',
            employer_title: "Accéder à Accès Santé Plus",
            employer_text: "Votre accès à Accès Santé Plus est fourni par votre employeur. Veuillez vérifier votre courriel et saisir le code à six carctères inscrit à côté du « code de l’employeur ».",
            employer_label: "Code de l’employeur",
            your_employer: "Votre employeur (nom de l'entreprise)",
            top_bar_email: 'Avez-vous une question? Envoyez-nous un courriel à',
            top_bar_email_address: 'support.accessanteplus@tapmedical.ca ',
            top_bar_question: 'Avez-vous une question?',
            bottom_text_link: ' Cliquez ici pour vous connecter',
            bottom_question: 'Vous avez un compte? ',
            bottom_questionForgotPass: 'Je me souviens de mon mot de passe.',
            title_login: "Bienvenue sur Accès Santé Plus!",
            main_page_title: 'Créer votre compte',
            main_page_text: 'Afin de créer votre compte et de vérifier votre identité, nous avons besoin de certaines informations. Une fois vos informations reçues et vérifiées, nous vous enverrons une confirmation de création de compte par courriel.',
            companyName: 'Nom de l’entreprise',
            label_search: "Recherche d'adresse",
            pharmacy_search: "Recherche de pharmacie",
            mobileNumber: 'Numéro de cellulaire',
            address: 'Adresse',
            address_2: 'Adresse Ligne 2 (optionnel)',
            city: 'Ville',
            medicareNumber: 'Numéro d’assurance maladie',
            medicareExpiration: 'Date d’expiration',
            medicareExpirationBase: 'Date d’expiration (le cas échéant)',
            lastName: 'Nom de Famille',
            firstName: 'Prénom',
            cellularNumber: 'Numéro de téléphone',
            phoneNumberUser: 'Numéro de cellulaire', 
            phoneNumberErrorText: 'Un numéro de cellulaire est nécessaire afin de recevoir des notifications par texte/SMS.', 
            emailAddress: 'Adresse courriel ',
            date_of_birth: "Date de naissance",
            sex_of_birth: "Sexe à la naissance",
            gender: {
                male: 'Masculin',
                female: 'Féminin'
            },
            name_warning: "Tel qu’indiqué sur votre carte d’identité émise par le gouvernement", 
            step_2_text: 'Quelle est votre adresse à la maison?',
            step_2_subtitle: 'À quelle pharmacie préférez-vous aller?',
            step_2_subtitle_remark: 'le cas échéant',
            step_2_location_pharmacy: 'ÉTAPE 2 - Emplacement et Pharmacie',
            text_button_not_doctor: 'Je n\'ai pas de médecin de famille',
            your_Location: 'Votre emplacement',
            your_pharmacy: 'Votre pharmacie',
            pharmacy_label: "Avez-vous une pharmacie préférée?",
            province: 'Province',
            search: 'Recherche',
            fax_number: 'Numéro de fax',
            spouse_last_name: 'Nom de famille du conjoint',
            spouse_first_name: 'Prénom du conjoint',
            step_4_text: 'Dites-nous à quelle pharmacie votre conjoint(e) préfère aller? ',
            optional: 'si applicable', 
            step_5_text_btn_no_doctor: 'Pas de médecin de famille',     
            step_5_text_btn_same_doctor: 'Même médecin de famille',  
            step5_password: "ÉTAPE 4 - Votre mot de passe", 
            step5_password_title: "Créer votre mot de passe",
            create_account: 'Créer mon compte', 
            success_create: 'Votre compte a été créé',
            finish_text: 'Merci de faire confiance à Accès Santé Plus pour vos besoins en soins de santé primaires. Vous êtes prêts à vous connecter et discuter avec un membre de notre équipe de soins à propos de tout problème de santé. ',
            finish_text_2: "Toutes les personnes à charge disposant d'une adresse courriel recevront une invitation distincte pour créer leur compte d'utilisateur.",
            finish_text_3: "Pour toute question n’hésitez pas à nous contacter à ",
            finish_text_4: "La clinique virtuelle Accès Santé Plus est ouverte du lundi au vendredi, ",
            finish_text_5: "de 8h00 à 20h00.",
            login: 'Connexion',
            step_3_medical_profile: 'ÉTAPE 3 - Profil médical',
            step_3_health_issues: 'Antécédents Médicaux',
            label_medication_list: 'Préciser',
            step_3_surgeries_medical_procedures: 'Antécédents Chirurgicaux',
            medical_history: 'Antécédents de santé',
            your_family_doctor: 'Votre médecin de famille',
            i_dont_have_fyamily_doctor: 'Je n\'ai pas de médecin de famille',
            fmd_map_label: "Médecin de famille ou votre clinique GMF",
            list_your_allergies: 'Énumérer toutes vos allergies',
            allergies_list: 'Allergies',
            medication_list: 'Medications',
            text_input: "Interventions chirurgicales...",
            list_all_your_medications: 'Énumérer tous vos médicaments',
            health_issues: 'Antécédents médicaux',
            list_exisisting_health_issues: 'Préciser',
            STEP_4_spouse: 'Pharmacie du conjoint',
            step_4: "ÉTAPE 4 - Personnes à charge",
            family_members: "Membres de la famille",
            add_family_members: "Ajouter des membres de la famille", 
            fmd_members_text_1: "Invitez les membres de votre famille sous votre compte. Les conjoints / partenaire et les enfants de 18 ans et plus éligibles recevront une invitation par courriel pour créer leur propre compte.",
            fmd_members_text_2: "Please note that if you do not add your family members now, you will need to reach out to our customer service to add dependants after your initial registration.",
            members_card_title_1: "Conjoint ou partenaire",
            members_card_title_2: "Enfant",
            members_card_text_1: "Votre conjoint ou partenaire peut bénéficier de votre couverture en créant son propre compte. Seuls les membres de la famille immédiate peuvent être ajoutés.",
            members_card_text_2: "Ajoutez votre/vos enfant(s) afin qu'ils puissent bénéficier de votre couverture.",
            members_btn: "Inviter",
            btn_add: "Ajouter",
            skip_step: "Passer cette étape",
            does_your_spouse_have_preferred_pharmacy: 'Votre conjoint a-t-il une pharmacie préférée ?',
            spouse_pharmacy: 'Antécédents médicaux',
            spouse_information: 'Informations sur le conjoint',
            add_spouse_title: "Inviter votre conjoint/partenaire",
            add_spouse_text: "Inscrivez l'adresse courriel de votre conjoint/partenaire afin de l'inviter à créer son propre compte. Votre conjoint/partenaire bénéficiera de votre couverture familiale et pourra ainsi avoir sa propre consultation.",
            invate_was_send: "Votre invitation a été envoyée à: ",
            invite_was_send_text: ", recevra un courriel indiquant les étapes à suivre afin de créer son propre compte.",
            invite_was_send_text_2: ", recevra un courriel indiquant les étapes à suivre afin de créer son propre compte.",
            invite_was_send_text_minor: " a été ajouté(e) à votre compte.",
            send_invitation: "Envoyer une invitation",
            success_invite: " a été invité(e) à se joindre à votre famille",
            btn_next_step: "Passer à l'étape suivante",
            btn_add_child: "Ajouter un enfant",
            btn_go_home: "Rentrer chez soi",
            or: "Ou",
            home: "Accueil",
            done: "J’ai terminé",
            label_child_first_name: "Prénom de l'enfant",
            label_child_last_name: "Nom de famille de l'enfant",
            health_card_child: "Numéro de carte d'assurance-maladie",
            add_child_title: "Ajouter mon enfant",
            add_child_text: "Les enfants de moins de 18 ans seront ajoutés au compte du parent. Inscrivez l'adresse courriel de votre enfant âgé de 18 ans et  plus afin de l'inviter à créer son propre compte.",
            add_child_text_2: "Inscrivez l'adresse courriel de votre enfant âgé de 18 ans et plus afin de l'inviter à créer son propre compte. Votre enfant bénéficiera de votre couverture familiale et pourra ainsi avoir sa propre consultation.",
            add_child_question: "Est-ce que votre enfant a 18 ans ou plus?",
            add_child_btn: "Ajouter un autre enfant", 
            add_to_family: "Ajouter à la famille",
            child_invited: "Vottre enfant recevra un courriel indiquant les étapes à suivre afin de créer son propre compte.",
            child_was_added: "{{name}} a été ajouté à votre compte.",
            your_spouse_will_receive: 'Votre conjoint recevra sa propre invitation à s\'inscrire à l\'adresse électronique fournie.',
            your_spouse_family_doctor: 'Le médecin de famille de votre conjoint',
            no_spouse_skip_this_step: 'Pas de conjoint ? Passez cette étape',
            my_spouse_family_doctor: 'Mon conjoint n\'a pas de médecin de famille',
            female: 'femme',
            male: 'homme',
            mm: 'MM',
            dd: 'JJ',
            yyyy: 'AAAA',
            step_2_3_title: 'Allergies et médicaments',
            btnCompleteRegistration: "S'inscrire",
            btn_accept: "J’accepte",
            btn_decline: "Je refuse",
            btn_success: "Commençons",
          },
        wttooltip: {
            textInfo1: 'La clinique est actuellement fermée. Nous sommes ouverts de 8h à 20h du lundi au vendredi.',
            textInfo2: 'Toutes les demandes envoyées en dehors de nos heures d’ouverture seront traitées en priorité le jour ouvrable suivant.',
            textInfo3: 'Veuillez noter que la clinique sera fermée le 24, 27 et le 31 décembre. Joyeuses Fêtes!',
            textInfo4: 'Veuillez noter que notre clinique sera fermée le vendredi 15 avril et le lundi 18 avril.',
            textBefore: "Veuillez prendre note que la clinique sera fermée pour {{name}}. Toutes les demandes envoyées en dehors de nos heures d’ouverture seront traitées en priorité le jour ouvrable suivant. ",
            textCurrent: "La clinique est actuellement fermée pour {{name}}. Nous sommes ouverts de 8h à 20h du lundi au vendredi. Toutes les demandes envoyées en dehors de nos heures d’ouverture seront traitées en priorité le jour ouvrable suivant. ",
            holiday_1: "la Journée nationale des patriotes (Fête de la Reine)",
            holiday_2: "la Fête Nationale de la St-Jean Baptiste",
            holiday_3: "la Fête du Canada",
            holiday_4: "la Fête du Travail",
            holiday_5: "l'Action de grâces",
            holiday_6: "le Jour de Noël",
            holiday_7: "le Jour de l'An",
            holiday_8: "Lundi de Pâques", 
            holiday_9: "Journée nationale de la vérité et de la réconciliation", 
            clinic_closed: "Désolé, la clinique est actuellement fermée. Nous sommes ouverts de 8h à 20h du lundi au vendredi. Toutes les demandes envoyées en dehors de nos heures d’ouverture seront traitées en priorité le jour ouvrable suivant."
        },
        questionnaire: {
            virtual_assistant: "Assistant Virtuel",
            not_upload_file: "Aucun fichier sélectionné",
            error: "Erreur",
            click_answer: "Veuillez cliquer sur l'une des réponses ci-dessous", 
            type_text: "Entrez votre réponse dans le champ de texte ci-dessous",
            something_wrong: "Quelque chose s'est mal passé",
            send: "Envoyer",
            archived_text: "Votre épisode de soins est terminé. N'hésitez pas à vous reconnecter et nous serons heureux de vous aider.", 
        },
        onesignal: {
            actionMessage: "Cliquez sur “s’abonner” pour recevoir les notifications venant de notre équipe de soins.", 
            acceptBtn: "S’abonner", 
            cancelBtn: "Plus tard", 
            text_1: "Abonnez-vous aux notifications",
            text_2: "Vous êtes abonné aux notifications", 
            text_3: "S'ABONNER",
            text_4: "SE DÉSABONNER",
            prenotify: "Cliquez pour vous abonner aux notifications",
            actionSubscribed: "Merci pour votre subscription!",
            actionResubscribed: "Vous êtes abonné aux notifications",
            actionUnsubscribed: "Vous ne recevrez plus de notifications",
            title_banner: "Ajouter l’Appli à l’écran d’accueil",
            text_banner_1: "Installez l’application web sur votre iphone afin d’autoriser les notifications. Appuyez sur  ", 
            text_banner_1_android: "Installez l’application web sur votre téléphone intelligent afin d’autoriser les notifications. Appuyez sur  ",
            text_banner_2: " puis choisir l’option “Ajouter sur l’écran d’accueil”.", 
            text_banner_3: " puis choisir l’option  “Installer l’application” / “Ajouter a l’écran d’accueil”.", 
            remind_btn: "Peut-être une autre fois",
            cancel_btn: "Ne pas me redemander",
            text_banner_ios_chrome: 'Pour afficher les notifications, veuillez ouvrir cette application dans le navigateur Safari.', 
        }
    }
};

export default fr;
