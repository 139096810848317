import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Image,
  Skeleton,
  Text,
  Flex,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { MessageDataType } from "../../app/dto/message-data.dto";
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Author } from "../../app/dto/author.dto";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { fileExtension } from "../../app/modules/utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import text_file from "../../assets/icons/text-file.svg";
import image_file from "../../assets/icons/file_image_3.svg";
import { QuestionnaireMessage } from "./questionnaire_message";
//import { saveAs } from "file-saver";

// moment.locale('fr', {
//   months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
//   monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
//   monthsParseExact : true,
//   weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
//   weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
//   weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
//   weekdaysParseExact : true,
//   longDateFormat : {
//       LT : 'HH:mm',
//       LTS : 'HH:mm:ss',
//       L : 'DD/MM/YYYY',
//       LL : 'D MMMM YYYY',
//       LLL : 'D MMMM YYYY HH:mm',
//       LLLL : 'dddd D MMMM YYYY HH:mm'
//   },
//   calendar : {
//       sameDay : '[Aujourd’hui à] LT',
//       nextDay : '[Demain à] LT',
//       nextWeek : 'dddd [à] LT',
//       lastDay : '[Hier à] LT',
//       lastWeek : 'dddd [dernier à] LT',
//       sameElse : 'L'
//   },
//   relativeTime : {
//       future : 'dans %s',
//       past : 'il y a %s',
//       s : 'quelques secondes',
//       m : 'une minute',
//       mm : '%d minutes',
//       h : 'une heure',
//       hh : '%d heures',
//       d : 'un jour',
//       dd : '%d jours',
//       M : 'un mois',
//       MM : '%d mois',
//       y : 'un an',
//       yy : '%d ans'
//   },
//   dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
//   ordinal : function (number) {
//       return number + (number === 1 ? 'er' : 'e');
//   },
//   meridiemParse : /PD|MD/,
//   isPM : function (input) {
//       return input.charAt(0) === 'M';
//   },
//   // In case the meridiem units are not separated around 12, then implement
//   // this function (look at locale/id.js for an example).
//   // meridiemHour : function (hour, meridiem) {
//   //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
//   // },
//   meridiem : function (hours, minutes, isLower) {
//       return hours < 12 ? 'PD' : 'MD';
//   },
//   week : {
//       dow : 1, // Monday is the first day of the week.
//       doy : 4  // Used to determine first week of the year.
//   }
// });

export interface MessageComponentProps {
  message: MessageDataType;
  belongsToUser: boolean;
  avatar?: string;
  onDocClick?: Function;
  isLastMessage: boolean; 
  setLoadingMessages: Function; 
  setInitialScroll: Function;
}

export const Message: React.FC<MessageComponentProps> = ({
  message,
  belongsToUser,
  avatar,
  onDocClick,
  isLastMessage,
  setLoadingMessages, 
  setInitialScroll,
}: MessageComponentProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === "en" ? "text_en" : "text_fr";
  moment.locale(t("lang"));

  const { state, messageService }: AppContextInterface = useContext(AppContext);
  const [documents, setDocuments] = useState<any>([]);
  const [loading, setLoading] = useState(false);  
  const [fallback, setFallback] = useState(
    <Skeleton width="50px" height="45px" marginTop="10px"></Skeleton>
  );
  const questionnaireCompleted = state.message.questionnaire_completed; 
  const [createdAt, setCreatedAt] = useState<string>(
    moment(message.created_at).locale(moment.locale()).fromNow()
  );

  useEffect(() => {
    if (
      message.documents &&
      message.documents.length > 0 &&
      typeof message.documents[0] !== "string" &&
      message.documents[0].id
    ) {
      setDocuments([...message.documents]);
    } else if (
      message.documents &&
      message.documents.length > 0 &&
      !message.documents[0].id &&
      questionnaireCompleted
    ) {
     let result = message.documents[0].map((item: any) => ({file_name: item.file_name, generatedLink: item.link }));
     setDocuments(result);
    } else if (!questionnaireCompleted &&  message.documents &&
      message.documents.length > 0) {
        let arrMessage = [{
          file_name: message.documents[0].name, 
          generatedLink: message.documents[0].data_url
        }];
        setDocuments(arrMessage); 
    }
 
    // under a min.
    const minutes = moment().diff(moment(message.created_at));
    let timerFor: number | undefined = 60000;
    // more than an hour.
    if (minutes >= 60 * 60000) {
      timerFor = 60 * 60000;
    }
    // more than a day skip.
    if (minutes >= 24 * 60 * 60000) {
      timerFor = undefined;
    }

    // timer.
    let interval: any = null;
    if (timerFor) {
      interval = setInterval(() => {
        setCreatedAt(moment(message.created_at).fromNow());
      }, timerFor);
    } else {
      let time =
        t("lang") !== "fr" ? "DD/MM/YYYY :: hh:mm  a" : "DD/MM/YYYY :: HH:mm";
      setCreatedAt(moment(message.created_at).format(time).toString());
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);


  const loadFile = (id: number, handler: any) => {
    let selectedDocument = documents.filter(
      (document: any) => document.id === id
    );
    if (selectedDocument.length) {
      messageService
        .getRefDoc(selectedDocument[0].id, state.session?.token)
        .then((link: any) => {
          selectedDocument[0].generatedLink = link;
          if(navigator.userAgent.toLowerCase().includes("chrome") ===
          false ) {
            window.location.href = link;
          } else {
            window.open(link, "_blank");
          }
          return
        });
    }
    setTimeout(() => setLoading(false), 2000)
  };


  const getName = (author: Author) => {
     if (author.display_name === "Virtual Assistant") {
      return t("questionnaire.virtual_assistant"); 
     }
     return author?.display_name ? author?.display_name : author?.name; 
  }


  const containsLink = (text: string) => {
    const urlRegex = /((http|https|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
    return text.match(urlRegex);
};

  const renderTextWithLinks = (text: string) => {
      if(text) {
          const parts = text.split(/(https?:\/\/[^\s]+)/g);
          return parts.map((part, index) => {
            if (containsLink(part)) {
              return (
                <Link href={part} style={{"color": "blue"}} textDecoration="underline"  isExternal>{part}</Link>
              );
            } else {
              return <span key={index}>{part}&nbsp;</span>;
            }
          });
      } else {
          return text; 
      }
  };

  const validatePhoneNumberText = (text: string) => {
    if(text.includes("mobile number") || text.includes("téléphone cellulaire")) {
      return text.replace(/\+1/i, '')
    } else {
      return text; 
    }
    
  }

  return (
    <Container
      display="flex"
      flexDirection={"column"}
      alignItems={belongsToUser === false ? "flex-start" : "flex-end"}
    >
     
      {documents &&
        documents.map((document: any, idx: number) => (
          <Container
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-end"
            flexDirection={belongsToUser === false ? "row" : "row-reverse"}
            mt="0.5%"
            mb="30px"
            key={idx}
          >
            <Avatar width="25px" height="25px" src={avatar} />
            <Box
              backgroundColor={
                belongsToUser === false ? "whiteAlpha.500" : "#E3FFF8"
              }
              ml="1%"
              mr="1%"
              maxWidth="70%"
              position="relative"
              border={`1px solid ${
                belongsToUser === false ? "#D5DFDE" : "#9DE6D5"
              }`}
              p="11px"
              borderTopRightRadius="20px"
              borderTopLeftRadius="20px"
              borderBottomRightRadius={belongsToUser === false ? "20px" : "0px"}
              borderBottomLeftRadius={belongsToUser === false ? "0px" : "20px"}
              box-shadow="0px 4px 8px #00000014"
            >
              {message?.author && (
                <Box position="absolute" top="-24px">
                  <Text
                    isTruncated
                    fontSize="12px"
                    color="#A3B2B1"
                    data-private
                  >
                    {`${
                      (message?.author?.name || message?.author?.display_name) 
                        ? getName(message?.author)
                        : state.session?.episode?.patient?.name
                    }${
                      message?.author?.role &&
                      message?.author?.role === "healthcare-provider"
                        ? ", HCP"
                        : ""
                    }${
                      message?.author?.role &&
                      message?.author?.role === "Administrator"
                        ? ", Admin"
                        : ""
                    }`}
                  </Text>
                </Box>
              )}

              {!!document?.generatedLink ? (
                <Box
                  backgroundColor={
                    belongsToUser === false ? "whiteAlpha.500" : "#E3FFF8"
                  }
                >
                  <Box display="flex" alignItems="center" height="85px">
                    <Box h="75px" w="75px">
                    {document.generatedLink !== "" &&
                      fileExtension(document.generatedLink)?.includes("pdf") ===
                        false && (
                        <Image
                          boxSize="75px"
                          data-private
                          onClick={() => {
                            loadFile(document.id, onDocClick);
                          }
                           
                          }
                          fallback={fallback}
                          onError={() =>
                            setFallback(
                              <Image
                                borderRadius="5px"
                                boxSize="75px"
                                border={`1px solid #E27878`}
                                src="/image_mui.svg"
                              />
                            )
                          }
                          objectFit="contain"
                          src={document.generatedLink}
                          maxHeight={"75px"}
                          maxW={"75px"}
                        />
                      )}
                    {document.generatedLink !== "" &&
                      fileExtension(document.file_name)?.includes("pdf") ===
                        true &&
                      (navigator.userAgent.toLowerCase().includes("safari") ===
                      true ? (
                        <Container
                          h="75px"
                          w="75px"
                          p="5px"
                          alignItems={
                            belongsToUser === false ? "flex-start" : "flex-end"
                          }
                          display="flex"
                          flexDirection="column"
                          onClick={() => {
                            //window.open(document.generatedLink, "_blank");
                            loadFile(document.id, onDocClick);
                          }}
                        >
                          <Image
                            borderRadius="5px"
                            boxSize="75px"
                            src={text_file}
                          />
                          {/* <Text fontSize='sm'>{t('chatScreen.downloadPDFLabel')}</Text> */}
                        </Container>
                      ) : (
                        <PDFDownloadLink
                          document={
                            <Image borderRadius="5px" src="/image_mui.svg" />
                          }
                          fileName={document.generatedLink}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <Container
                                w="75px"
                                h="75px"
                                padding="5px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Image boxSize="75px" src={text_file} />
                                {/* <Text fontSize='sm'>{t('chatScreen.downloadPDFLabel')}</Text> */}
                              </Container>
                            )
                          }
                        </PDFDownloadLink>
                      ))}
                    </Box>  
                    <Text py="5px" pl="5px" textAlign={"center"} fontSize="sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                      {document.file_name}
                    </Text>
                  </Box>
                  <Divider mb={"5px"} mt={"5px"} />
                  <Text color="grey" fontSize="xs" textAlign="end">
                    {createdAt}
                  </Text>
                </Box>
              ) : (
                <Box
                  onClick={() => {
                    if(!loading) {
                      setLoading(true);
                      loadFile(document.id, onDocClick); 
                    }

                  }}
                  position="relative"
                  backgroundColor={
                    belongsToUser === false ? "whiteAlpha.500" : "#E3FFF8"
                  }
                >
                 {document?.mime?.split("/")[0] !== "image" && <Image
                    cursor={"pointer"}
                    position="absolute"
                    boxSize={"35px"}
                    top="25%"
                    left="20px"
                    src="/download-circle.svg"
                  />}
                  <Box height="85px" display="flex" alignItems="center">
                    {document?.mime?.split("/")[0] === "image" ? (
                      <Image
                        boxSize={"75px"}
                        cursor="pointer"
                        data-private
                        src={document?.thumbnail_url}
                        alt=""
                      />
                    ) : (
                      <Image
                        boxSize={"75px"}
                        cursor="pointer"
                        src={text_file}
                        alt=""
                      />
                    )}
                    <Text py="5px" pl="5px"  textAlign={"center"} fontSize="sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                      {document.file_name}
                    </Text>
                  </Box>
                  <Divider mb={"5px"} mt={"5px"} />
                  <Text color="grey" fontSize="xs" textAlign="end">
                    {createdAt}
                  </Text>
                </Box>
              )}
            </Box>
          </Container>
        ))}

      {message.contents && (
        <Container
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-end"
          flexDirection={belongsToUser === false ? "row" : "row-reverse"}
          mt="0.5%"
          mb="30px"
        >  
          <Avatar width="25px" height="25px" src={avatar} />
          <Box
            backgroundColor={
              belongsToUser === false ? "whiteAlpha.500" : "#E3FFF8"
            }
            ml="1%"
            mr="1%"
            maxWidth="70%"
            position="relative"
            border={`1px solid ${
              belongsToUser === false ? "#D5DFDE" : "#9DE6D5"
            }`}
            p="11px"
            borderTopRightRadius="20px"
            borderTopLeftRadius="20px"
            borderBottomRightRadius={belongsToUser === false ? "20px" : "0px"}
            borderBottomLeftRadius={belongsToUser === false ? "0px" : "20px"}
            box-shadow="0px 4px 8px #00000014"
          >
            {message?.author && (
              <Box position="absolute" top="-24px">
                <Text isTruncated fontSize="12px" color="#A3B2B1" data-private>
                  {`${
                    (message?.author?.name || message?.author?.display_name)
                      ? getName(message?.author)
                      : state.session?.episode?.patient?.name
                  }${
                    message?.author?.role &&
                    message?.author?.role === "healthcare-provider"
                      ? ", HCP"
                      : ""
                  }${
                    message?.author?.role &&
                    message?.author?.role === "Administrator"
                      ? ", Admin"
                      : ""
                  }`}
                </Text>
              </Box>
            )}

             <Text color="#304358" whiteSpace="pre-wrap" fontFamily={"Mulish"} data-private>
                {!belongsToUser && message?.active_question ? validatePhoneNumberText(message?.active_question[lang]) : renderTextWithLinks(validatePhoneNumberText(message.contents))}
              </Text>

             {!belongsToUser && message?.active_question && <QuestionnaireMessage isLastMessage={isLastMessage} activeQuestion={message?.active_question} setLoadingMessages={setLoadingMessages} setInitialScroll={setInitialScroll}/>}

            <Divider mb={"5px"} mt={"5px"} />
            <Text color="grey" fontSize="xs" textAlign="end">
              {createdAt}
            </Text>
          </Box>
        </Container>
      )}
    </Container>
  );
};
